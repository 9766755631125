<template>
  <div class="error-wrapper">
    <h1 class="error-message">שגיאה</h1>
    <h2>התרגום למוצר {{item_name}} נכשל!</h2>
    <div class="button-wrapper">
      <button @click="$emit('try_again')" class="retry-button">נסה שנית</button>
      <button @click="$emit('translate_manuel')" class="translate-button">תרגם ידנית</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  emits:['try_again','translate_manuel'],
  props: {
    item_name: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup() {
    
    return {  

    };
  }
}
</script>

<style scoped>
.error-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: red;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
.error-message{
    color: red;
}

.button-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.retry-button {
  background: lightgreen;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.translate-button {
  background: lightgrey;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
